//Services like Blockchain, Website & Mobile App

import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import {SiBlockchaindotcom} from 'react-icons/si'
import {RiComputerFill} from 'react-icons/ri'
import {AiFillMobile} from 'react-icons/ai'
import {BiMobileAlt} from 'react-icons/bi'

const ServiceList = [
    {
        /*Description Correction*/ 
        icon: <SiBlockchaindotcom />,
        title: 'Blockchain',
        description: 'Code Encoders specialized in public and private Blockchains; we provide top-notch Blockchain and Web3 services which will transform your business to decentralize the world. '
    },
    {
        icon: <RiComputerFill />,
        title: 'Web-based application',
        description: 'Code Encoders provides you with the most innovative front-end designs and develops responsive and dynamic full-stack Web applications.'
    },
    {
        icon: <AiFillMobile />,
        title: 'Mobile application',
        description: 'Developing business-oriented Mobile applications with cutting-edge technologies. We do cross-platform and native app development.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper section-gapBottom-hSub">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">{val.title}</h4>
                                <div >
                                <p  className="service-text-center color-gray mb--0" >{val.description}</p>
                                </div>
                            </div>
                            

                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;