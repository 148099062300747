//Content after WE DO MUCH MORE

import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import consulting from '../../assets/Services/consulting.webp'
import contentWriting from '../../assets/Services/contentWriting.webp'
import socialMedia from '../../assets/Services/socialMedia.webp'
import uIuX from '../../assets/Services/uIuX.webp'
import ReactReadMoreReadLess from 'react-read-more-read-less'


const ServiceList = [
    {
        image: socialMedia,
        title: 'Digital Marketing',
        description: 'Boost your online presence with our digital marketing services. From SEO to social media, PPC to content creation, we will help you reach your target audience and drive conversions.'
    },
    {
        image: uIuX,
        title: 'UI/UX Designing',
        description: 'At Code Encoders we expertly craft visually appealing and user-friendly interfaces for optimal user experience. Specializing in UI/UX design for web and mobile apps.'
    },
    {
        image: consulting,
        title: 'Consulting',
        description: 'Stuck somewhere or need advice or a helping hand with your project? We do that too; we have been training and consulting individuals and groups of people for the past 2 years.'
    },
    {
        image: contentWriting,
        title: 'Content Writing',
        description: 'Crafting compelling and effective content for websites, blogs, products, and marketing materials. Specializing in SEO-friendly writing for maximum visibility and reach.'
    }
]

const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content section-gapBottom-hSub">
                                    <h4 className="title mb--20 mt--10"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    {/* <ReactReadMoreReadLess  
                                    charLimit={100}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                > */}
                    {val?.description}
                    {/* </ReactReadMoreReadLess> */}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;