import React from "react";

const BrandList = [
  {
    image: "./images/brand/brand-01.png",
    text:'Javascript'
  },
  {
    image: "./images/brand/brand-02.png",
    text:'Node js'

  },
  {
    image: "./images/brand/brand-03.png",
    text:'React js'

  },
  {
    image: "./images/brand/brand-04.png",
    text:'Sql'

  },
  {
    image: "./images/brand/brand-05.png",
    text:'Bitcoin'

  },
  {
    image: "./images/brand/brand-06.png",
    text:'Ethereum'

  },
  {
    image: "./images/brand/brand-07.png",
    text:'Swift'

  },
  {
    image: "./images/brand/brand-08.png",
    text:'Rust'

  },
  {
    image: "./images/brand/brand-09.png",
    text:'Laravel'

  },
  {
    image: "./images/brand/brand-10.png",
    text:'Shopify'

  },
  {
    image: "./images/brand/brand-11.png",
    text:'Metaverse'

  },
  {
    image: "./images/brand/brand-12.png",
    text:'Mongo DB'

  },
  {
    image: "./images/brand/brand-13.png",
    text:'Photoshop'

  },
  {
    image: "./images/brand/brand-14.png",
    text:'Ripple'

  },
  {
    image: "./images/brand/brand-15.png",
    text:'Unity'

  },
  {
    image: "./images/brand/brand-16.png",
    text:'Css'

  },
  {
    image: "./images/brand/brand-17.png",
    text:'DevOps'

  },
  {
    image: "./images/brand/brand-18.png",
    text:'Illustrator'

  },
  {
    image: "./images/brand/brand-19.png",
    text:'AWS'

  },
  {
    image: "./images/brand/brand-20.png",
    text:'Redux'

  },


];

const BrandTwo = ({ brandStyle }) => {
  return (
    <ul className={`brand-list ${brandStyle}` } >
      {BrandList.map((data, index) => (
        <li key={index}>
          <a href="#">
            <img  
 src={`${data.image}`}  alt="Brand Image" />
  <p>{data?.text}</p>

          </a>
          
     
        </li>
      ))}
    </ul>
  );
};

export default BrandTwo;
