import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import testimonialOne from "../../assets/Home page/TestimonialOne.webp";
import testimonialTwo from "../../assets/Home page/TestimonialTwo.webp";
import testimonialThree from "../../assets/Home page/TestimonialThree.webp";
import testimonialFour from "../../assets/Home page/TestimonialFour.webp";
import testimonialFive from "../../assets/Home page/TestimonialFive.webp";
import testimonialSix from "../../assets/Home page/TestimonialSix.webp";
import testimonialSeven from "../../assets/Home page/TestimonialSeven.webp";
import { useEffect,useState } from "react";
import testimonialEight from "../../assets/Home page/TestimonialEight.webp";
import { Link } from "react-router-dom";
import ReactReadMoreReadLess from 'react-read-more-read-less'

const testimonialData = [
  {
    image: testimonialOne,
    name: "Alexander Johnson",
    // designation: 'Sr Product Designer',
    location: "CO Miego, AD, USA",
    description:
      "“Code Encoders team did an excellent job of developing our blockchain-based supply chain management system. They were professional, responsive, and delivered the project on time and within budget. We are very happy with the results and would recommend them to anyone looking for a top-notch blockchain development team.„",
  },
  {
    image: testimonialTwo,
    name: "Olivia Garcia",
    // designation: 'App Developer',
    location: "Bangladesh",
    description:
      "“We engaged Code Encoders to build a decentralized application for our business and were extremely impressed with the quality of their work. Their team was knowledgeable, efficient, and delivered a final product that exceeded our expectations. We would highly recommend them for any blockchain development project.„",
  },
  {
    image: testimonialThree,
    name: "Daniel Smith",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“We were looking for a reliable blockchain development team to help us launch our digital token and Code Encoders delivered exactly that. They were able to understand our requirements and delivered a high-quality product. The team was professional, responsive, and always available to answer any questions we had. We are very satisfied with their work and would recommend them to anyone.„",
  },
  {
    image: testimonialFour,
    name: "Sophia Kim",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“We were very pleased with the work Code Encoders did on our blockchain-based voting system. They were able to understand our requirements and deliver a system that met our needs. Their team was professional, knowledgeable, and responsive throughout the project. We would definitely use them again for future projects.„",
  },
  {
    image: testimonialFive,
    name: "Patrick Sullivan",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“We were in need of a web application that could streamline our internal processes and increase efficiency within our company. The team at Code Encoders was able to understand our needs and deliver a custom solution that exceeded our expectations. Their attention to detail and ability to make adjustments and improvements as needed throughout the project made for a seamless and successful launch. We highly recommend them for any web development needs.„",
  },
  {
    image: testimonialSix,
    name: "Jasmine Chen",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“We engaged Code Encoders to build a custom web application for our e-commerce business. They were able to understand our unique requirements and deliver a robust and user-friendly platform that has greatly increased sales and customer satisfaction. The team was easy to work with and always responsive to our needs. We couldn't be happier with the final product and highly recommend them to any business looking to improve their online presence.„",
  },
  {
    image: testimonialSeven,
    name: "Muhammad Saif",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“As a small start-up, we were on a tight budget but needed a professional and functional web application to launch our service. The team at Code Encoders was able to work with us to understand our needs and deliver a cost-effective solution that met all of our requirements. Their expertise and experience were invaluable in helping us launch a successful product. We would highly recommend them to any business looking for a reliable and affordable web development partner.„",
  },
  {
    image: testimonialEight,
    name: "Liam Murphy",
    // designation: 'Accounts Manager',
    location: "Poland",
    description:
      "“We needed a mobile app to enhance our customer's experience and increase engagement with our brand. The team at Code Encoders delivered a beautifully designed, user-friendly app that exceeded our expectations. Their expertise in both design and development made for a smooth and seamless process. We have received overwhelmingly positive feedback from our customers and have seen a significant increase in brand loyalty since launching the app. We highly recommend the team at Code Encoders for any mobile app development needs.„",
  },
];
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialOne = ({ column, teamStyle }) => {

    
  return (
    <div className="row row--15 mb--80">
      <Slider {...settings}>
        {testimonialData.map((data, index) => (
          <div className={`${column}`} key={index}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className={`rn-box-card ${teamStyle}`}>
                <div className="inner">
                  <figure className="thumbnail">
                    <img src={data?.image} alt="Corporate React Template" />
                  </figure>
                  <figcaption className="content">
                    <ReactReadMoreReadLess  
                readMoreText={"Read more ▼"}
                charLimit={100}
                readLessText={"Read less ▲"}

                >
                    {data?.description}
                    </ReactReadMoreReadLess >
                    <br></br>
                    <br></br>
                    <h2 className="title">{data.name}</h2>
                    <h6 className="subtitle theme-gradient">
                      {data.designation}
                    </h6>
                  </figcaption>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialOne;
