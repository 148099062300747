//About Us Page

import React from "react";
import SEO from "../common/SEO";

import AboutFour from "../elements/about/AboutFour";
import AboutCustom from "../elements/about/AboutCustom";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Layout from "../common/Layout";
import ceoImg from "../assets/home & about images/about2.webp";
import cooImg from "../assets/home & about images/about1.webp";
import ScrollAnimation from "react-animate-on-scroll";

const AboutUs = () => {
  return (
    <>
      <SEO title="About Us" />
      <Layout>
        <div className="section-gapTop section-gapBottom-hSub">
          <SectionTitle
            textAlign="text-center"
            radiusRounded=""
            subtitle="Our Company's About Details
              "
            title="About Us"
            description=""
          />
        </div>

        {/* Start Info Area  */}
        <div className="gapArea">
        <div className="service-area section-gapBottom-hSub slideFadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content ">
                  <h3 className="title theme-gradient">
                    Simplicity is prerequisite of reliability.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10 justify" >
                  We create the best software using cutting-edge technology and
                  make it usable and reliable. We have vast expertise from
                  Blockchain development, Web app development, and Mobile app
                  development to content writing, digital marketing, and UI/UX
                  designing. We have a team of top-notch developers and have up
                  to 7 years of experience in creating quality software
                  solutions.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Info Area  */}
        <Separator />

        {/* Start Mission Statement  */}
        <div className="service-area section-gapTop section-gapBottom-hSub slideFadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <p className="mb--10 justify">
                  Bring diverse skills and expertise with a wide range of
                  technical and non-technical backgrounds to our clients for
                  providing the best solution in the industry and making their
                  products the best in the digital world with top-quality work.
                </p>
              </div>
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title theme-gradient justify">Mission Statement</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Mission Statement  */}

        <Separator />
        {/* Start Vision Statement */}

        <div className="service-area section-gapTop section-gapBottom slideFadeInUp">
          <div className="container ">
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="row vision-statement ">
                <div className="col-lg-6">
                  <div className="content">
                    <h3 className="title theme-gradient justify">Vision Statement</h3>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <p className="mb--10 justify">
                    Code Encoders envisions providing sustainable, innovative,
                    and efficient CODE to our clients that empower our clients
                    to achieve their goals and drive their business forward and
                    achieve the best work environment for our ENCODERS.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div></div>

        {/* End Vision Statement */}

        <Separator />
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <AboutFour image={ceoImg} />
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <AboutCustom image={cooImg} />
        </ScrollAnimation>

        <Separator />

        <div className="rwt-timeline-area section-gapTop">
          <div className="container section-gapBottom mb--30">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" teamStyle="team-style-three" />
                    </div>
                </div> */}
        {/* End Elements Area  */}

        <Separator />

        <Separator />
      </Layout>
    </>
  );
};

export default AboutUs;
