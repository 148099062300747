import React from 'react';
import PortfolioDetailsContent from "../elements/portfolio/PortfolioDetailsContent";
import PortfolioData from "../data/portfolio/PortfolioData.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";


const PortfolioDetails = ({match: {params: {id,urlTitle}}}) => {
    const portfolioId = parseInt(id, 10)
    
    let tempData=[];
   let metaData = PortfolioData?.map((data) => data);
   metaData?.forEach((element) => {
    element?.singleData?.forEach(el=>{
        el.category=element?.category
        tempData?.push(el)

    })
}

);





    const data = tempData?.filter(item => item?.urlTitle?.toLowerCase() === urlTitle.toLowerCase());
    return (
        <>
            <SEO title="Portfolio Details || Doob" />
            <Layout>
                <PortfolioDetailsContent data={data[0]} />
            </Layout>
        </>
    )
}

export default PortfolioDetails;
