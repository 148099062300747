//Services Provided for you + We Do Much More

import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";

import Separator from "../separator/Separator";
import BrandTwo from "../brand/BrandTwo";
import BrandOne from "../brand/BrandOne";
import ServiceTwo from "./ServiceTwo";
import ScrollAnimation from "react-animate-on-scroll";

const Service = () => {
  return (
    <>
      <SEO title="Service || Doob - React Business  Template" />
      <Layout>
        <div className="main-content">
          {/* Start Service Area  */}
          <div className="rn-service-area section-gapTop section-gapBottom-hSub">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="What we can do for you"
                    title="Services provide for you"
                    description="With a team of veteran developers, Code Encoders offer a wide spectrum of IT solutions, mainly Block chain development, Web application development and Landing pages, Mobile application development, and much more. We build client relationships by providing them with quality CODE."   /*Description Correction*/ 
                  />
                </div>
              </div>
              <ServiceOne
                serviceStyle="service__style--1 icon-circle-style"
                textAlign="text-center"
              />
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Service Area  */}
          <div className="rn-service-area section-gapTop section-gapBottom-hSub ">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="What we can do for you"
                    title="We do much more"
                    description="We just don’t help you with mainstream technologies but also have a professional team for Digital marketing, UI/UX designing, Consultation on any of your ongoing projects, Content writing for your blog or products, and much more."
                  />
                </div>
              </div>
              <ServiceTwo />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />

          {/* Start Brand Area  */}
          <div className="rwt-brand-area section-gapTop">

          <ScrollAnimation 
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}>

            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="What we can do for you"
                    title="Tools We Use"
                    description=""
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 section-gapBottom-hSub">
                  <BrandTwo brandStyle="brand-style-2" />
                </div>
              </div>
            </div>
            </ScrollAnimation>
          </div>
          {/* End Brand Area  */}

          <Separator />

          {/* Start Brand Area  */}
          <div className="rwt-brand-area section-gapTop section-gapBottom-hSub">
          <ScrollAnimation 
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}>
          <div className="container">
          <div className="row">
          <div className="col-lg-12">
          <SectionTitle
          textAlign="text-center"
          radiusRounded=""
          subtitle="Our Awesome Client"
          title="Our Clients"
          description=""
          />
          </div>
          </div>
          <div className="row">
          <div className="col-lg-12 mt--40 section-gapBottom">
                  <BrandOne brandStyle="brand-style-1" />
                </div>
              </div>
            </div>
            </ScrollAnimation>
          </div>
          {/* End Brand Area  */}

          <Separator />
        </div>
      </Layout>
    </>
  );
};

export default Service;
