import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import { useLocation } from 'react-router-dom';
import {ToastContainer,toast,Zoom,Bounce} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

// const Result = () => {

//     return (
//         <p className="success-message">Your Message has been successfully sent. We will get back to you soon.</p>
//     )
// }

function ContactForm({props , formStyle}) {
    const location=useLocation()

    const successToast=()=>{
        toast.success('Your Message has been successfully sent. We will get back to you soon. ', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    }

    
    let templateId=location?.pathname === '/contact'?'template_gqvb6bn':"template_o95eibb"

    // const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_eard45k', 
            templateId, 
            e.target, 
            '2FOxLaMviOYclbtH8'
        )
        .then((result) => {
            console.log(result.text);
            successToast()
            }, 
            (error) => {
                console.log(error.text);
                toast.error(error.text, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    });
            }
        );
        e.target.reset();
        // showresult(true);
    };

   

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>

            <ToastContainer  draggable={false} transition={Zoom} autoClose={8000} theme="colored"/>
            <div className="form-group">
                <input 
                type="text" 
                name="fullname"
                placeholder="Your Name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div>

            <div className="form-group textAreaForm">
                <textarea 
                name="message"
                placeholder="Project Details"
                required
                >
                </textarea>
            </div>

            <div className={location?.pathname ==='/get-a-quote'?'btn-parent':'form-group'} >
                <button   className="btn-default btn-large section ">Submit Now</button>
            </div> 
{/* 
            <div className="form-group ">
                {result ? <Result /> : null}
            </div>  */}
        </form>
    )
}
export default ContactForm;
