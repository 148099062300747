import React from "react";

const PortfolioDetailsContent = ({ data }) => {
  const categoryMetaData = [];
  categoryMetaData?.push(data?.category);

  const categoryHelper = data?.projectCategories.forEach((cat) =>
    categoryMetaData?.push(cat)
  );
  const categories = [];
  categoryMetaData?.forEach((cat) => {
    if (categories?.indexOf(cat) === -1) {
      categories.push(cat);
    }
  });

  return (
    <div className="rwt-portfolio-details rn-section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="details-list">
                <div className="thumbnail alignwide">
                  <img
                    className="radius w-100"
                    src={`${process.env.PUBLIC_URL}/${data?.largeImage}`}
                    alt="Corporate Image"
                  />
                </div>

                <div className="row mt--40 row--30">
                  <div className="col-lg-6">
                    <div className="content-left">
                      <h4 className="title">{data?.title}</h4>
                      <div className="single-list-wrapper">
                        <div className="single-list">
                          <label>Technologies:</label>

                          <span>{data?.technologiesUsed}</span>
                        </div>

                        <div className="single-list">
                          <label>Categories:</label>
                          {categories?.map((cat, index) => {
                            return <span>{(index ? ", " : "") + cat}</span>;
                          })}
                        </div>
                        {/* <div className="single-list">
                                                    <label>Client:</label>
                                                    <span>{data?.client}</span>
                                                </div> */}
                        <div className="single-list ">
                          <label>Url:</label>
                          <a href={data?.projectUrl} target="_blank">{data?.projectUrl}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt_md--30 mt_sm--30">
                    <div className="content-right">
                      <h5 className="subtitle">Description</h5>
                      <div className="description portfolio-desc-space ">
                        <p>{data?.body}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="portfolio-gallery mt--40">
                {data?.gallery?.map((imgObj) => (
                  <div className="gallery mt--30">
                    <div className="thumbnail">
                      <img
                        className="radius w-100"
                        src={`${process.env.PUBLIC_URL}/${imgObj?.image}`}
                        alt="Portfolio Image"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailsContent;
