import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";

// Pages import Here
import Marketing from "./pages/Marketing";
import AboutUs from "./pages/AboutUs";
import Service from "./elements/service/Service";
import Portfolio from "./elements/portfolio/Portfolio";
import Testimonial from "./elements/testimonial/Testimonial";
import Contact from "./elements/contact/Contact";
import PortfolioDetails from "./pages/PortfolioDetails";
import GetAQuote from "./elements/get-a-quote/GetAQuote";

// Import Css Here
import "./assets/scss/style.scss";

const App = () => {
  useEffect(() => {
    document.querySelector("body").className = "dark";
  }, []);

  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} exact component={Marketing} />
          <Route path={`${process.env.PUBLIC_URL}/service`} exact component={Service} />
          <Route path={`${process.env.PUBLIC_URL}/testimonial`} exact component={Testimonial} />
          <Route path={`${process.env.PUBLIC_URL}/contact`} exact component={Contact} />
          <Route path={`${process.env.PUBLIC_URL}/get-a-quote`} exact component={GetAQuote} />
          <Route path={`${process.env.PUBLIC_URL}/about-us`} exact component={AboutUs} />
          <Route path={`${process.env.PUBLIC_URL}/portfolio`} exact component={Portfolio} />
          <Route path={`${process.env.PUBLIC_URL}/portfolio-details/:urlTitle`} component={PortfolioDetails} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
