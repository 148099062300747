import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BrandOne from "../brand/BrandOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactForm from "../contact/ContactForm";
import HeaderOne from "../../common/header/HeaderOne";
import Layout from "../../common/Layout";
import getAQuoteTwo from '../../assets/get a code/get-a-quote.webp';

const GetAQuote = () => {
  useEffect(() => {
    document.title = 'Code Encoders';
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Code Encoders</title>
      </Helmet>

      <div className="rwt-portfolio-details rn-section-gap ">
        <div className="container section-gapBottom-hSub mb--10">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="inner">
                <div className="details-list">
                  <div className="thumbnail alignwide slideFadeInUp">
                    <img
                      className="radius w-100"
                      src={getAQuoteTwo}
                      alt="Corporate Image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt--40">
              <SectionTitle
                textAlign="text-center"
                radiusRounded=""
                subtitle="Get A Quote"
                title="Get A Quote "
                description=""
              />
            </div>
            <div className="mt--40 getQuoteContactForm">
              <ContactForm formStyle="contact-form-1" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GetAQuote;
