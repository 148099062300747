//Homepage

import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";

import FooterTwo from "../common/footer/FooterTwo";
import ServiceOne from "../elements/service/ServiceOne";
import homeBanner from "../../src/assets/Home page/Banner Image 2.webp";
import { Element } from "react-scroll";

import Typed from "react-typed";
import { FiArrowRight } from "react-icons/fi";

import SectionTitle from "../elements/sectionTitle/SectionTitle";

import { Link } from "react-router-dom";

import Separator from "../elements/separator/Separator";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import CircleProgress from "../elements/progressbar/CircleProgress";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Copyright from "../common/footer/Copyright";
import ScrollAnimation from "react-animate-on-scroll";

const Marketing = () => {
  return (
    <>
      <SEO title="Marketing" />
      <main className="page-wrapper">
        {/* <HeaderTopBar /> */}
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750 slideFadeInUp">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two ">
                    Revolutionizing
                    <br />
                    Industry
                    <br /> With{" "}
                    <Typed
                      strings={["Development", "Innovation", "Consultation"]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    Code Encoders help the clients succeed by creating
                    innovative Blockchain Applications, Web Applications, and
                    Mobile Applications, and Digital experiences.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/get-a-quote"
                    >
                      New Customer{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="/contact"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img src={homeBanner} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area section-gapTop section-gapBottom-hSub">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--20">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="Services provide for you"
                  description="With a team of veteran developers, Code Encoders offer a wide spectrum of IT solutions, mainly Blockchain development, Web application development and Landing pages, Mobile application development and much more. We build client relationship by providing them quality CODE."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start About us */}

        <div className="service-area section-gapTop section-gapBottom-hSub">
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className="container">
              <div className="row">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Company's About Details"
                  title="About Us"
                />
                <div className="col-lg-6">
                  <div className="content home-about ">
                    <h3 className="title">
                      Leading the way in innovation, delivering exceptional
                      software solutions
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 home-about-desc">
                  <p className="mb--10 justify">
                    We create best software using cutting edge technology and
                    make it useable and reliable. We have vast expertise from
                    Blockchain development, Web app development, Mobile app
                    development to content writing, digital marketing and UI/UX
                    designing. We have a team of top-notch developers and have
                    up-to 7 years of experience of creating quality software
                    solutions.{" "}
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>

        {/* End About us */}

        <Element id="portfolio" name="portfolio">
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className="rwt-portfolio-area section-gapTop section-gapBottom-hSub">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Why Choose Us"
                      title="Our Projects"
                      description="Quality is what makes us better than others, our portfolio is a proof of that."
                    />
                  </div>
                </div>
                <PortfolioOne Column="col-lg-6 col-md-6 col-sm-6 col-12 mt--30 portfolio no-overlay" />
              </div>
            </div>
          </ScrollAnimation>
        </Element>

        <Separator />
        <div className="rwt-progressbar-area section-gapTop section-gapBottom-hSub">
          <div className="container">
            <div className="row mb--25">
              <div className="col-lg-10 offset-lg-1">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Our Expertise."
                  title="Companies Expertise"
                  description="We have a diverse team of Encoders who works hard and fast and provide with quality services."
                />
              </div>
            </div>
            <div className="col-lg-10 offset-lg-1 section-gapBottom-hSub">
              <CircleProgress />
            </div>
          </div>
        </div>

        <Separator />
        {/* Start Testimonial Area  */}
        <div className="rwt-testimonial-area section-gapTop section-gapBottom-hSub ">
          <div className="container"> 
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="What our clients are Saying"
                  description="Over 50 satisfied customers. See how our products and services have made a difference."
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-sm-6 col-12 "
              teamStyle="card-style-default testimonial-style-one style-two"
            />
          </div>
        </div>
        {/* End Testimonial Area  */}

        <Separator />

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default Marketing;
