import React, { useState, useEffect,useMemo } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";
import { useCallback } from "react";
import ScrollAnimation from "react-animate-on-scroll";
const filters = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "Blockchain",
  },
  {
    id: 3,
    text: "Web Applications",
  },
  {
    id: 4,
    text: "Mobile Applications",
  },
];

const alldata = PortfolioData;
const PortfolioOne = ({ Column }) => {
  const [getAllItems] = useState(alldata);
  const [dataVisibleCount, setDataVisibleCount] = useState(4);
  const [dataIncrement] = useState(2);
  const [noMorePost, setNoMorePost] = useState(false);
  const [activeFilter, setActiveFilter] = useState("");
  
  const [visibleItems, setVisibleItems] = useState([]);

  const [totalItems,setTotalItems]=useState()

  const [loadMoreClicked,setLoadMoreClicked]=useState(false)

  const [portfolioMenuClicked,setPortfolioMenuClicked]=useState();
  

  let metaData;
  let tempData=[];
  let defaultDataCount=4
  // let dataToDisplay=[];
  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());

    metaData = getAllItems?.map((data) => data.singleData);
    metaData?.forEach((element) => {
        element?.forEach(el=>{
            tempData?.push(el)

        })
      });

      setTotalItems(tempData)

    //  dataToDisplay=tempData?.slice(0,dataVisibleCount)
 
    setVisibleItems(tempData?.slice(0,dataVisibleCount));
  }, []);

  useEffect(()=>{
    if(loadMoreClicked==true){

  
      setVisibleItems(totalItems?.slice(0,dataVisibleCount))
      setLoadMoreClicked(false)

    }
  },[loadMoreClicked])

  useEffect(()=>{
    if(portfolioMenuClicked==true){
      setVisibleItems(totalItems?.slice(0,dataVisibleCount))
      setPortfolioMenuClicked(false)


    }

  },[portfolioMenuClicked])

useEffect(()=>{
  if(dataVisibleCount>=totalItems?.length){
    setNoMorePost(true)
    
  }else{
    setNoMorePost(false)
  }
},[dataVisibleCount,portfolioMenuClicked])


  const handleChange = (e) => {
setPortfolioMenuClicked(true)
setDataVisibleCount(4)
    setActiveFilter(e.target.textContent.toLowerCase());
     tempData=[];

    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      metaData = getAllItems.map((data) => data.singleData);
      metaData?.forEach((element) => {
        element?.forEach(el=>{
            tempData?.push(el)

        })
      });
      // dataToDisplay=tempData?.slice(0,dataVisibleCount)
      setTotalItems(tempData)
      setLoadMoreClicked(false)


      setVisibleItems(tempData?.slice(0,dataVisibleCount))
    } else {
      // metaData = getAllItems.filter(
      //   (data) =>
      //     data?.category.toLowerCase() ===
      //     e.target.textContent.toLowerCase()
      // );
      // tempData=metaData[0]?.singleData
      // // dataToDisplay=tempData?.slice(0,dataVisibleCount)

      let categoryHelper=[]

      metaData=getAllItems.map(data=>data?.singleData)
      metaData?.forEach((element)=>{
        element?.forEach((el)=>{
          categoryHelper?.push(el)
        })
      })

      let rawData=categoryHelper?.forEach((data)=>{
        data?.projectCategories?.forEach(el=>{
          if(el==e.target.textContent)
          tempData?.push(data)
        })
      })

      setTotalItems(tempData)
      
      // setVisibleItems(tempData?.slice(0,dataVisibleCount))

      



    }
  };


  
  

  const handleLoadmore = () => {

    setLoadMoreClicked(true)
    let tempCount = dataVisibleCount + dataIncrement;
    
      setDataVisibleCount(tempCount);
   
        // dataToDisplay=totalItems?.slice(0,dataVisibleCount)


        // setVisibleItems(dataToDisplay);
      
  };

  



  
  
  
  
  return (
    <>
    <ScrollAnimation 
    animateIn="fadeInUp"
    animateOut="fadeInOut"
    animateOnce={true}>
      <div  className="row row--15">
        <div className="col-lg-12">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
            {filters.map((filter) => (
              <li className="list-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter ? "current" : " "
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div id="portfolio" className="row row--15">


        {visibleItems?.map((item,ind) => (
          <div key={ind} className={Column}>
            <PortfolioItem portfolio={item} projectId={item?.projectId} />
          </div>
        ))}
        </div>
        
        <div className="row row--15 ">
        <div className="col-lg-12">
          <div className="rwt-load-more text-center mt--50 section-gapBottom-hSub mb--20">
            <button
              className="btn btn-default btn-large btn-icon "
              onClick={()=>{
                handleLoadmore();
              }}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "No Item Here"
              ) : (
                <span>
                  Load More
                  <span className="icon">
                  <FaSpinner />
                  </span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
      </ScrollAnimation>
    </>
  );
};

export default PortfolioOne;
