import React from 'react';

const BrandList = [
   
    {
        image: './images/brand/brand-69.webp'
    },
    {
        image: './images/brand/brand-70.webp'
    },
    {
        image: './images/brand/brand-71.webp'
    },
    {
        image: './images/brand/brand-22.webp'
    },
    {
        image: './images/brand/brand-23.webp'
    },
    {
        image: './images/brand/brand-24.webp'
    },
    {
        image: './images/brand/brand-25.webp'
    },
    {
        image: './images/brand/brand-26.webp'
    },
    {
        image: './images/brand/brand-27.webp'
    },
    {
        image: './images/brand/brand-28.webp'
    },
    {
        image: './images/brand/brand-72.webp'
    },
    {
        image: './images/brand/brand-73.png'
    }
]

const BrandOne = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img  src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandOne;
