import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
import ScrollAnimation from "react-animate-on-scroll";

const Contact = () => {
    return (
        <>
            <SEO title="Contact || Doob - React Business  Template" />
            <Layout>
            <div className="main-content">
            {/* Start Contact Area  */}
            <div className="rwt-contact-area rn-section-gap">
            
            <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 section-gap mt--20 section-gapBottom-hSub">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                        title = "Get In Touch"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                            
                        </div>
                        </ScrollAnimation>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;