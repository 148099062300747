import React from "react";
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";

import SectionTitle from "../sectionTitle/SectionTitle";

import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";

const ContactOne = () => {
  return (
    <>
      <div className="row row--15 ">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row ">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact Phone Number</h4>
                    <p>
                      <a href="tel:+92 322 4001400">+92 322 4001400</a>
                    </p>
                    <p>
                      <a href="tel:+92 306 9570169">+92 306 9570169</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Email Address</h4>
                    <p>
                      <a href="mailto:info@codeencoders.com">
                        info@codeencoders.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Our Location</h4>
                    <p>
                    <a href="https://www.google.com/maps/place/Code+Encoders/@31.4738807,74.3736856,17z/data=!3m1!4b1!4m6!3m5!1s0x391907d7fa424041:0x7969c11d9f30a68e!8m2!3d31.4738762!4d74.3762605!16s%2Fg%2F11stnz46vc?entry=ttu">
                      2nd Floor, 102 Commercial Area, Sector Y DHA Phase 3,
                      Lahore, Punjab, Pakistan
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-gapBottom-hSub section-gapTop">
      <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    <div className="col-lg-12 section-gap">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Contact Form"
                                        title = "Feel Free To Contact Us"
                                        description = ""
                                    />
                                </div>
                    </ScrollAnimation>
                    </div>
      <div className="row row--15 section-gapBottom-hSub">
        <div className="col-lg-7">
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
          <ContactForm formStyle="contact-form-1" />
          </ScrollAnimation>
          </div>
          <div className="col-lg-5 mt_md--30 mt_sm--30">
          <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
          >
          <GoogleMapStyle />
          </ScrollAnimation>
        </div>
      </div>
    </>
  );
};
export default ContactOne;
