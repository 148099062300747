import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import TestimonialOne from "./TestimonialOne";

import Separator from "../separator/Separator";

const Testimonial = () => {
    return (
        <>
            <SEO title="Testimonial || Doob - React Business  Template" />
            
            <Layout>
             

                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="What our clients are Saying."
                  description="Over 50 satisfied customers. See how our products and services have made a difference."
                />
              </div>
            </div>
            <TestimonialOne
              column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
              teamStyle="card-style-default testimonial-style-one style-two"
            />
          </div>
        </div>
                    {/* End Elements Area  */}
                    <Separator />


               


                </div>

            </Layout>
        </>
    )
}
export default Testimonial;