import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.8293289614517!2d74.37368557506748!3d31.47388074943845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391907d7fa424041%3A0x7969c11d9f30a68e!2sCode%20Encoders!5e0!3m2!1sen!2s!4v1686051435447!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
