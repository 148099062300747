//CEO

import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import ceoImg from "../../assets/home & about images/about1.webp";
import cooImg from "../../assets/home & about images/about2.webp";

const PopupData = [
  {
    id: "01",
    image: ceoImg,
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 section-gapTop section-gapBottom-hSub">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="about-title theme-gradient">
                  Syed Mustafa Haider
                </h3>
                <h5 className="about-subtitle">CEO | Co-Founder</h5>

                <p className="mb--10 justify">
                  You can't connect the dots looking forward; you can only
                  connect them looking backward. So you have to trust that the
                  dots will somehow connect in your future. If you want to
                  achieve perfection in your organization you have to make
                  yourself perfect and the organization will get pulled up with
                  you. This is a big lesson and this is what I’ve learned in 7
                  years of my experience. We put our heart and soul into Code
                  Encoders to achieve our vision and become the best Software
                  Solution Organization in the World. Our team of experts is
                  dedicated to developing cuttingedge software that can help
                  businesses of all industries and sizes to streamline their
                  operations and achieve their goals. Whether you're looking for
                  a custom-built application, a powerful CRM system, or an
                  enterprise-level platform, we have the skills and expertise to
                  deliver a solution that meets your needs.
                </p>
                <div>
                  <a href="https://instagram.com/shah.naqash9?igshid=YmMyMTA2M2Y=">
                    <FaInstagram fontSize={"30px"} />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/syed-mustafa-haider-397989b9?originalSubdomain=pk
"
                  >
                    {" "}
                    <FaLinkedin fontSize={"30px"} className="socialIcon" />
                  </a>
                  <a href="mailto:ceo@codeencoders.com">
                    <AiOutlineMail fontSize={"30px"} className="socialIcon" />
                  </a>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
