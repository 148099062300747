//COO

import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiCheck } from "react-icons/fi";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";

const PopupData = [
  {
    id: "01",
    image: "./images/bg/bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const AboutCustom = ({ image }) => {
  return (
    <div className="about-area about-style-4 section-gapTop ">
      <div className="container ">
        <div className="row row--40 align-items-center about-custom">
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                <h3 className="about-title theme-gradient">Hamas Ali Sabir</h3>
                <h5 className="about-subtitle">COO | Co-Founder</h5>

                <p className="mb--10 justify">
                  As the young COO of Code Encoders, I am excited to lead our
                  team in creating innovative software solutions that will help
                  businesses thrive in the digital age. That's why we're always
                  striving to push the boundaries of what's possible and create
                  groundbreaking solutions for our clients. At Code Encoders, we
                  believe that software development is not just about writing
                  lines of code. It's about understanding the needs of our
                  clients and using our expertise to craft solutions that
                  address those needs. We work closely with our clients to
                  ensure that our products are tailored to their specific
                  requirements and that they receive the support they need to
                  succeed. In addition to creating exceptional software, we are
                  also dedicated to fostering a culture of innovation within our
                  company. We encourage our team members to think outside the
                  box and to challenge themselves to come up with creative
                  solutions to complex problems. We're constantly looking for
                  new ways to innovate and improve, and I'm honored to be
                  leading this charge alongside such a talented and dedicated
                  team. Together, we're building a brighter future for our
                  clients and the tech industry as a whole.
                </p>
                <div>
                  <a href=" https://instagram.com/me_hammas?igshid=YmMyMTA2M2Y=">
                    <FaInstagram fontSize={"30px"} />
                  </a>
                  <a href=" https://www.linkedin.com/in/hamasalisabir?originalSubdomain=pk">
                    <FaLinkedin fontSize={"30px"} className="socialIcon" />
                  </a>
                  <a href="mailto:coo@codeencoders.com">
                    {" "}
                    <AiOutlineMail fontSize={"30px"} className="socialIcon" />
                  </a>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            {PopupData.map((item) => (
              <div className="video-btn" key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCustom;
